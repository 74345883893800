import { render, staticRenderFns } from "./CustomerContactUs.vue?vue&type=template&id=d981621c&scoped=true&"
import script from "./CustomerContactUs.vue?vue&type=script&lang=js&"
export * from "./CustomerContactUs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d981621c",
  null
  
)

export default component.exports